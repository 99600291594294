<template>
  <ui-component-modal
    modalTitle="Add reservation space"
    modalSize="large"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :savingSuccessMessage="'Data saved'"
    :savingErrorMessage="'Something went wrong'"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :hideSaveButton="true"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div>
        <check-availability
          :channelId="cart.ChannelId"
          :locationId="cart.LocationId"
          :meetingtypeId="cart.MeetingtypeId"
          :showDateInput="false"
          :startDate="new Date(cart.StartDate)"
          :endDate="new Date(cart.EndDate)"
          :startMinutes="startMinutes"
          :endMinutes="endMinutes"
          :voucherId="cart.VoucherId"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import cartProvider from '@/providers/cart'

export default {
  components: {
    'check-availability': CheckAvailability,
  },

  props: {
    autoSearch: {
      default: false,
      type: Boolean,
    },
    canChangeDate: {
      default: false,
      type: Boolean,
    },
    countryId: {
      default: 0,
      type: Number,
    },
    endMinutes: {
      default: 0,
      type: Number,
    },
    seats: {
      default: 1,
      type: Number,
    },
    settingId: {
      default: 0,
      type: Number,
    },
    showDateInput: {
      default: false,
      type: Boolean,
    },
    startMinutes: {
      default: 0,
      type: Number,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
  },

  created() {
    EventBus.$on('spaceSelected', (space) => {
      this.addCartSpace(space)
    })
  },

  beforeDestroy() {
    EventBus.$off('spaceSelected')
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    addCartSpace(selectedSpace) {
      let self = this
      self.isSaving = true

      selectedSpace.Id = 0
      selectedSpace.CartId = self.cart.Id

      cartProvider.methods
        .saveCartSpace(self.cart.CartKey, selectedSpace)
        .then((response) => {
          if (response.status === 200) {
            self.setCart(response.data)
            self.isSavingSuccess = true

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Space is added!',
            })

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          self.isSavingError = true
          ////console.log(error.response)
          EventBus.$emit('showToast', {
            type: 'error',
            message: 'Space not saved!',
          })
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
